import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        keycloak: null,
        reportScan: null,
        userProfile: null
    },
    getters: {
    },
    mutations: {
        changeKeycloak(state, payload) {
            state.keycloak = payload
        },
        changeReportScan(state, payload) {
            state.reportScan = payload
        },
        changeUserProfile(state, payload) {
            state.userProfile = payload
        }
    },
    actions: {
    }
});